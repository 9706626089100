<template>
  <base-section id="logo-designs">
    <base-section-heading
      color="grey lighten-2"
      icon="mdi-drawing-box"
      title="Design Trends For Your Business"
    />
    <v-container>
      <v-carousel
        cycle
        interval="5000"
        hide-delimiter-background
        show-arrows-on-hover
        height="100%"
      >
        <v-carousel-item
          v-for="hovercard in hovercards"
          :key="hovercard.title"
        >
          <base-hover v-bind="hovercard" /><!-- transition="fab-transition" -->
        </v-carousel-item>
      </v-carousel>
      <base-divider horizontal />
    </v-container>
    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="4"
        >
          <base-info-card v-bind="card" />
          <!-- <v-divider horizontal /> -->
        </v-col>
        <!--
        </v-row>
        <v-row>
         <v-col
          v-for="n in 13"
          :key="n"
          cols="6"
          md="2"
        >
          <a href="https://wa.me/+916383906364?text=Hello%2C%20I%27m%20looking%20for%20an%20info%20at%20Hi5Magic%20on%20Logo%20Designs">
          <base-img
            :src="require(`@/assets/logodesigns/${n}.jpg`)"
            color="black"
            width="112"
            :title="`logo-${n}`"
            tile
          />
          </a>
        </v-col>
        <v-col
          cols="6"
          md="2"
        >
          <a href="https://www.facebook.com/pg/hi5magic/photos/?tab=album&album_id=129357898987786&ref=page_internal">
            Logo Designs
          </a>
          <base-img
            :src="require('@/assets/logodesign.jpg')"
            color="black"
            max-width="250"
          />
        </v-col>
        <v-col
          cols="6"
          md="2"
        >
          <a href="https://www.facebook.com/pg/hi5magic/photos/?tab=album&album_id=129356922321217&ref=page_internal">
            Flyers/Brochures
          </a>
          <base-img
            :src="require('@/assets/flyer.png')"
            color="black"
            max-width="250"
          />
        </v-col>
        <v-col
          cols="6"
          md="2"
        >
          <a href="https://www.facebook.com/hi5magic">
            Social Media Posts
          </a>
          <base-img
            :src="require('@/assets/fbpost.jpg')"
            color="black"
            max-width="250"
          />
        </v-col>
        <v-col
          cols="6"
          md="2"
        >
          <a href="https://www.facebook.com/pg/hi5magic/photos/?tab=album&album_id=119141636676079&ref=page_internal">
            Benchmark Designs
          </a>
          <base-img
            :src="require('@/assets/benchmark.jpg')"
            color="black"
            max-width="250"
          />
        </v-col>-->
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionDesignWork',

    data: () => ({
      cards: [
        {
          title: 'Modicum',
          subtitle: 'Simple, Clean and Minimum Design',
          // text: 'Similique sunt in culpa qui officia deserunt mollitia animi, id est laborut dolorum fuga.harum quidem rerum facilis estexpedita distinctio.',
          text: 'Are you looking for a simple and effective design? We have it for you.',
          callout: '01',
        },
        {
          title: 'Creative Fonts',
          subtitle: 'Atypical, Unusual, Fancy, Destructive Text',
          // text: 'Similique sunt in culpa qui officia deserunt mollitia animi, id est laborut dolorum fuga.harum quidem rerum facilis estexpedita distinctio.',
          text: 'Do you want the text alone to do the wonders? We will do it for you.',
          callout: '02',
        },
        {
          title: 'Colors and Shades',
          subtitle: 'Glossy, Gradient, Neon Colors',
          text: 'Does your business require strong colors? We have you covered.',
          callout: '03',
        },
        {
          title: 'Alignment',
          subtitle: 'Enclosed, Extended, Wrap Text, Overlap, Spaces',
          text: 'Whether you want the design elements to be spaced or overlapped or aligned unusually, We can create it for you.',
          callout: '04',
        },
        {
          title: 'Classic',
          subtitle: 'Elegant, ',
          text: 'If you are looking for the classic and more elegant designs , We are here to do it happily for you',
          callout: '05',
        },
        {
          title: 'Eye Catchy',
          subtitle: 'Customized as per your wish ',
          text: 'Incase you are looking for unique designs, We are available to design your thoughts.',
          callout: '06',
        },
      ],
      hovercards: [
        {
          title: 'Logo Designs',
          text: 'https://www.facebook.com/pg/hi5magic/photos/?tab=album&album_id=129357898987786&ref=page_internal',
          imagename: 'logodesign.jpg',
          icon: 'mdi-facebook',
          color: 'blue',
          subtitle: 'Logo Is The Face and Identification Of A Brand ',
        },
        {
          title: 'Flyers and Brochures',
          text: 'https://www.facebook.com/pg/hi5magic/photos/?tab=album&album_id=129356922321217&ref=page_internal',
          imagename: 'flyer.jpg',
          icon: 'mdi-facebook',
          color: 'blue',
          subtitle: 'Flyers, Brochures, Banners And Other Printable Designs ',
        },
        {
          title: 'Social Media Posts',
          text: 'https://www.facebook.com/hi5magic',
          imagename: 'fbpost.jpg',
          icon: 'mdi-facebook',
          color: 'blue',
          subtitle: 'Facebook, Instagram, LinkedIn, WhatsApp Posts For Promotions ',
        },
        {
          title: 'Client Deliverables',
          text: 'https://www.facebook.com/pg/hi5magic/photos/?tab=album&album_id=119141636676079&ref=page_internal',
          imagename: 'benchmark.jpg',
          icon: 'mdi-facebook',
          color: 'blue',
          subtitle: 'Benchmark Designs Delivered To Our Clients',
        },
      ],
    }),
  }
</script>
